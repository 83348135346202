/* Styling button one for Desktop */
@media screen and (max-width:1440px) {
    .btn_one{
        width: 10.438rem;
        height: 3.5rem;
        background-color: var(--dark-blue);
        color: white;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: bold;
        font-size: 1.125rem;
        transition: ease-in-out 0.2s;
    }
}

/* Styling button one for Tablet */

@media screen and (max-width:800px) {
    .btn_one{
        width: 8.75rem;
        height: 3rem;
        background-color: var(--dark-blue);
        color: white;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: bold;
        font-size: 1rem;
    }
}

/* Button on mobile device stays the same as tablet, css inherited. */

/* Button one hover state */

.btn_one:hover{
    opacity: 0.5;
    transition: ease-in-out 0.2s;
}