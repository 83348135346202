@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700;800&display=swap');

*,
*::before,
*::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background-image: linear-gradient(180deg, hsl(0, 0%, 100%), hsl(236, 100%, 97%));
  font-family: "Plus Jakarta Sans", sans-serif;
}

html{
  scroll-behavior: smooth;
}
