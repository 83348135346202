/* Styling footer button for Desktop */
@media screen and (max-width:1440px) {
    .footer_btn{
        width: 10.438rem;
        height: 3.5rem;
        background-image: var(--purple-gradient);
        color: white;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: bold;
        font-size: 1.125rem;
        transition: ease-in-out 0.2s;
    }
}

/* Styling footer button for tablet */

@media screen and (max-width:800px) {
    .footer_btn{
        width: 8.75rem;
        height: 3rem;
        background-image: var(--purple-gradient);
        color: white;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: bold;
        font-size: 1rem;
    }
}

/* Button on mobile device stays the same as tablet, css inherited. */

/* Footer button hover state */

.footer_btn:hover{
    opacity: 0.5;
    transition: ease-in-out 0.2s;
}