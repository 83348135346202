/* Styling Gridcards for desktop */

@media screen and (max-width: 1440px) {
    .grid_container{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        height: 46.75rem;
        margin-inline: auto;
        margin: 0rem 7rem;
        gap: 1.875rem;
    }

    .gradient_card,
    .main_card{
        width: 21.875rem;
        height: 20.125rem;
        border-radius: 0.625rem;
        display: flex;
    }

    .gradient_card{
        background-image: var(--pink-gradient);
        padding: 5rem 2rem;
        align-items: center;
    }

    .gradient_card_title{
        font-size: var(--font-32);
        color: var(--white);
    }

    .main_card{
        background-color: var(--white);
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem;
        padding-top: 4rem;
        position: relative;
    }

    .card_img{
        position: absolute;
        top: -2rem;
    }

    .card_title{
        font-size: var(--font-24);
    }

    .card_description{
        color: var(--gray);
        font-size: var(--font-18);
    }

    .card_link{
        text-decoration: none;
        color: var(--pink);
        font-weight: 700;
        transition: 0.2s ease-in-out;
    }

    .card_link:hover{
        color: var(--light-pink);
    }
}

/* Stylin Gridcards for tablet */

@media screen and (max-width: 800px) {

    .grid_container{
        margin-top: 5.813rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 62.438rem;
        margin-inline: auto;
        margin: 0rem 2rem;
        gap: 0.625rem;
    }

    .gradient_card,
    .main_card{
        height: 16.188rem;
        width: 21.188rem;
        border-radius: 0.625rem;
    }

    .gradient_card{
        background-image: var(--pink-gradient);
        padding: 0rem 2rem;
        padding-top: 20%;
    }

    .gradient_card_title{
        font-size: var(--font-24);
        color: var(--white);
        line-height: 2rem;
    }

    .main_card{
        background-color: var(--white);
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        padding: 3rem 1.7815rem;
    }

    .card_img{
        position: absolute;
        top: -2rem;
    }

    .card_title{
        font-size: 1.25rem;
        font-weight: 800;
    }

    .card_description{
        font-family: 1rem;
        color: var(--gray);
        width: 286px;
    }

    .card_link{
        font-size: var(--font-18);
        font-weight: 700;
        text-decoration: none;
        color: var(--pink);
    }
}

/* Styling Gridcards for mobile */

@media screen and (max-width:500px) {

    .grid_container{
        display: grid;
        grid-template-columns: 1fr;
        height: 105.938rem;
        gap: 0rem;
        margin-top: 0rem;
        margin-inline: auto;
        margin:0rem;
    }

    .gradient_card{
        margin-inline: auto;
        width: calc(100vw - 2rem);
        height: 7.5rem;
        background-image: var(--pink-gradient);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3.5rem 3.563rem;
    }

    .gradient_card_title{
        font-size: var(--font-24);
        color: var(--white);
        line-height: 2rem;
    }

    .main_card{
        margin-inline: auto;
        width: calc(100vw - 2rem);
        height: 16.188rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        background-color: var(--white);
        border-radius: 10px;
        padding: 3rem 1.7815rem;
        position: relative;
    }

    .card_img{
        position: absolute;
        top: -2rem;
    }

    .card_title{
        font-size: 1.25rem;
        font-weight: 800;
    }

    .card_description{
        font-size: 1rem;
        color: var(--gray);
    }

    .card_link{
        font-size: var(--font-18);
        font-weight: 700;
        text-decoration: none;
        color: var(--pink);
    }
}